import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Seo } from '../components/Seo'
import { Layout } from '../components/Layout'
export default function ThankYou() {
  return (
    <Layout>
		<Seo title="Thank you" />
		<Container>
			<div style={{ minHeight: '80vh', display: "flex", flexDirection: 'row', paddingTop:'4em' }}>
				<div className="my-auto">
					<Row>
						<Col md={8} className='pb-4'>
							<h1 className='hero-text'>
								Thank you 
							</h1>
							<p className='pt-2'>
                            Thank you for showing interest in excerpts. You will receive an email along when the beta program rolls out along with 3 months of excerpt gold subscription. 
                            </p>
                            <p>
                                Happy life :)
                            </p>
						</Col>
						{/* <Col md={5}>
							<StaticImage src="../images/screenshot.png" alt="Excerpt Screenshot" placeholder='blurred' />
						</Col> */}
					</Row>
				</div>
			</div>
		</Container>
	</Layout>
  )
}
